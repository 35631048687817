<template>
        <div class="cookiePopup">
                <div class="text">
                    <p>{{text}}</p>
                </div>
                <div class="btn">
                    <button @click="$emit('getAccept',1)">I accept</button>
                    <span class="textlink" style="margin-left : 10%;" @click="$emit('get',1)">Learn more</span>
                </div>
        </div>
</template>

<script>
export default {
    data() {
        return {
            text : "Mongolia.travel uses cookies to improve your user experience. Cookies are small text files that are saved on your computer or mobile device when you visit the site. You can change your cookie preferences at any time.",
        }
    },
    components : {
    }
}
</script>

<style lang="scss" scoped>
    .cookiePopup {
        position: fixed;
        right: 2.34%;
        bottom: 0;
        z-index: 98;
        width: 22.56%;
        height: 20.09%;
        background: rgba(#2C2C2C, 0.9);
        .text {
            font-size: 15px;
            max-width: 90%;
            max-height: 70%;
            color: white;
            padding-left: 9.1%;
            padding-top: 5%;
        }
        .btn {
            padding-bottom: 5%;
            width: 100%;
            button {
                background-color: #E13036;
                border-color: #E13036;
                border-style: solid;
                border-width: 1px;
                color: white;
                font-weight: bold;
                text-align: left;
                margin-left: 7.1%;
            }
            .textlink {
                color: white;
                cursor: pointer;
                }
        }
    }

@media only screen and (min-width: 1280px) {
    .cookiePopup {
       
        .text {
            font-size: 10px;
            max-width: 90%;
            max-height: 50%;
            color: white;
        }
        .btn {
            width: 60%;
            button {
                font-size: 10px;
            }
            .textlink {
                font-size: 10px;
            }
        }
    }
}

@media only screen and (min-width: 1920px) {
    .cookiePopup {
       
        .text {
            font-size: 15px;
            max-width: 90%;
            max-height: 70%;
            color: white;
        }
        .btn {
            width: 45%;
            button {
            }
            .textlink {
                }
        }
    }
}
</style>
