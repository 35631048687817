<template>
  <div class="home">
    <exp-wc></exp-wc>
    <Navhome />
    <Homemenu />
    <NavrightHome />
    <CookiePopup
      @get="checkData"
      @getAccept="accept"
      class="popup"
      :class="{'showPopup' : showPopup}"
    />
    <CookieSetting @getCheck="save" class="cookie" :class="{'isShow' : isShow}" />
    <!-- <Popup /> -->

    <Footer />
  </div>
</template>

<script>
import Navhome from "@/components/Navhome";
import Homemenu from "@/components/HomeMenu";
import NavrightHome from "@/components/NavrightHome";
import Popup from "@/components/Popup";
import CookiePopup from "@/components/CookiePopup";
import CookieSetting from "@/components/CookieSetting";
import Footer from "@/components/Footer";
import { log } from "util";

export default {
  name: "home",
  data() {
    return {
      isShow: false,
      showPopup: false
    };
  },
  components: {
    Navhome,
    Homemenu,
    NavrightHome,
    Popup,
    CookiePopup,
    CookieSetting,
    Footer
  },
  methods: {
    checkData(number) {
      this.isShow = true;
      this.showPopup = true;
      this.$cookies.set("isAcceptCookies", true, "1y");
    },
    save(number) {
      this.isShow = false;
      this.$cookies.set("isAcceptCookies", true, "1y");
      this.$cookies.set("isAcceptCookiesPerformance", true);
      this.$cookies.set("isAcceptCookiesPersonalize", true);
      this.$cookies.set("isAcceptCookiesMarketing", true);
    },
    accept(number) {
      this.isShow = false;
      this.showPopup = true;
      this.$cookies.set("isAcceptCookies", true, "1y");
      this.$cookies.set("isAcceptCookiesPerformance", true);
      this.$cookies.set("isAcceptCookiesPersonalize", true);
      this.$cookies.set("isAcceptCookiesMarketing", true);
    }
  },
  mounted() {
    const acceptBetaPopup = this.$cookies.get("isAcceptCookies");
    if (acceptBetaPopup) {
      this.showPopup = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.home {
  background-image: url("../assets/img/bg-mongolia.jpg");
  background-size: cover;
  background-position: center;
  height: 100%;

  .cookie {
    display: none;

    &.isShow {
      display: block;
    }
  }
  .popup {
    display: block;
    &.showPopup {
      display: none;
    }
  }
}
</style>