<template>
  <div class="Navhome">
    <b-navbar variant="faded" type="light" toggleable="lg">
      <b-navbar-toggle target="nav_text_collapse"></b-navbar-toggle>
      <b-navbar-brand to="/">
        <img :src="logoHome" alt="Mongolia">
      </b-navbar-brand>
      <b-collapse is-nav id="nav_text_collapse">
        <b-navbar-nav class="mc">
          <b-nav-item
            to="traveler-snapshots"
            @click="clickComingSoon(1)"
            :class="{'coming-soon': liIndex == 1}"
          >TRAVELER SNAPSHOTS</b-nav-item>
          <b-nav-item to="plan">
            PLAN YOUR TRIP
            <!-- <img :src="comingSoon" class="comingSoon" alt="comingSoon"> -->
          </b-nav-item>
          <b-nav-item to="event-calendar">
            EVENTS CALENDAR
            <!-- <img :src="comingSoon" class="comingSoon" alt="comingSoon"> -->
          </b-nav-item>
          <b-nav-item href="#" @click="clickComingSoon(4)" :class="{'coming-soon': liIndex == 4}">
            OFFERS
            <img :src="comingSoon" class="comingSoon" alt="comingSoon">
          </b-nav-item>
          <b-nav-item to="map">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="104.159"
              height="52.705"
              viewBox="0 0 104.159 52.705"
              class="map-icon"
            >
              <g transform="translate(-1313.5 -33.564)">
                <text
                  class="map-icon-text"
                  transform="translate(1347 67.043)"
                  fill="#ffffff"
                  font-size="16"
                  font-family="Poppins-SemiBold, Poppins"
                  font-weight="600"
                >
                  <tspan x="0" y="0">MAP</tspan>
                </text>
                <path
                  class="map-icon-outline"
                  d="M-204.344,156.309l6.1,6.215,1.929,6.623-1.577,3.354,1.366,3.138,13.2,6.288,2.9,7.357,18.034,1.974,12.379,4.575,6.162-3.454,11.778-2.574,5.826-5.681-2.11-3.109,2.812-4.114,4.812,1.5,7.662-5.7,3.7-4.672,6.859-1.271.323-1.589-5.268-4.132-2.523.413-2.711.986-3,1.313-.816-3.611,1.592-7-7.723-.732-10.05,6.5-7.814-1.2-9.2-4.278-5.062,1.691-4.238-2.384-.574-3.647-9.22-3.963-3.134,3.655.631,3.69-3.12,3.736-8.384-2.7-.549-2.261-5.422-2.207-12.484,4.527Z"
                  transform="translate(1518.845 -110.564)"
                  fill="none"
                  stroke="#ffffff"
                  stroke-linejoin="round"
                  stroke-width="2"
                ></path>
              </g>
            </svg>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import LogoHome from "../assets/img/logo-mongolia-home.png";
import iconMapMongolia from "../assets/img/icon-map.png";
import coming from "../assets/img/coming-soon.png";

export default {
  name: "Navhome",
  data() {
    return {
      logoHome: LogoHome,
      iconMM: iconMapMongolia,
      comingSoon: coming,
      liIndex: 0
    };
  },
  methods: {
    clickComingSoon(item) {
      this.liIndex = item;
      setTimeout(() => {
        this.liIndex = 0;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped>
.Navhome {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  z-index: 2;
  .mc {
    margin: auto;

    .nav-item {
      padding: 10px 30px 0;
      .nav-link {
        color: #fff;
        position: relative;
        transition: 0.3s ease color;

        .map-icon-text {
          transition: 0.3s ease fill;
        }

        .map-icon-outline {
          transition: 0.3s ease stroke;
        }

        &:hover {
          color: #E13036;

          .map-icon-text {
            fill: #E13036;
          }
          .map-icon-outline {
            stroke: #E13036;
          }
        }

        .comingSoon {
          opacity: 0;
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          transition: 0.2s ease-out;
        }
      }

      &:last-child {
        padding-top: 0;
        .nav-link {
          img {
            width: 100px;
          }
        }
      }

      &.coming-soon {
        .comingSoon {
          opacity: 1;
          transition: 0.2s ease-in;
        }
      }
    }
  }
}
</style>

