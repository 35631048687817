<template>
  <div class="popup" :class="{'isPopup' : statusPopup}">
    <img :src="closePopup" class="closePopup" alt="closePopup" @click="closeToPopup">
    <img :src="logoPopup" alt="logoPopup">
    <div class="text">
      <span>Welcome to Mongolia.travel (beta)!</span>
      <br>We are preparing something inspiring and we ask
      all visitors to take a tour and leave your feedback.
      Check back frequently to see our new and
      interactive features.
    </div>
  </div>
</template>

<script>
import logoPopup from "../assets/img/logo-popup.png";
import closePopup from "../assets/img/close-popup.png";

export default {
  name: "popup",
  data() {
    return {
      logoPopup: logoPopup,
      closePopup: closePopup,
      statusPopup: true
    };
  },
  methods: {
    closeToPopup() {
      this.statusPopup = false;
      this.$cookies.set("acceptBetaPopup", true);
    }
  },
  mounted() {
    const acceptBetaPopup = this.$cookies.get("acceptBetaPopup");
    if (acceptBetaPopup) {
      this.statusPopup = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.popup {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 734px;
  height: 370px;
  padding: 40px;
  border: 6px #00336e solid;
  border-radius: 6px;
  background-color: #fff;
  z-index: 11;

  .closePopup {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: -20px;
  }

  .text {
    margin-top: 20px;
    font-size: 25px;
    color: #00336e;
    span {
      font-weight: bold;
    }
  }

  &.isPopup {
    display: block;
  }
}
</style>

