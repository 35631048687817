<template>
    <div class="CookieSetting">
        <div class="description">
            <VuePerfectScrollbar class="scroll-area" @ps-scroll-y="scrollHanle">
                <p class="topic">Cookie preferences</p>
                <p class="text">Here you can see what kind of cookie data we collect from Mongolia.travel. All the data that we collect is anonymous. Please choose here which cookies you'd like us to use. You can change your preferences at any time by going to www.Mongolia.travel/cookies. To learn more, please read our privacy policy.</p>
                <label class="container"> Automatically activated cookies (always required)
                    <input type="checkbox" checked disabled>
                    <span class="checkmark-disabled"></span>
                </label>
                <p class="textCheckbox">Automatically activated cookies are always in use because online services need them to function.<br><span style="color : #E13036;">Purpose: Website functionality</span></p>
                <p class="text-red"></p>

                <label class="container"> Performance
                    <input type="checkbox" checked>
                    <span class="checkmark"></span>
                </label>
                <p class="textCheckbox">Performance cookies help us to follow visitor numbers, understand how visitors use our website and to identify returning visitors.<br><span style="color : #E13036;">Purposes: Personalisation, Analytics</span></p>
                
                <label class="container"> Personalisation
                    <input type="checkbox" checked>
                    <span class="checkmark"></span>
                </label>
                <p class="textCheckbox">Content personalisation cookies help us to personalise the content and offers we provide to you, based on your use of our website and other digital services.<br><span style="color : #E13036;">Purposes: Personalisation, Analytics</span></p>
                
                <label class="container"> Marketing
                    <input type="checkbox" checked>
                    <span class="checkmark"></span>
                </label>
                <p class="textCheckbox">Marketing-related cookies help us to place targeted advertising on other websites and services outside of Mongolia.travel.<br><span style="color : #E13036;">Purpose: Advertising</span></p>

                <br>
                <button @click="$emit('getCheck',1)">Save</button>
            </VuePerfectScrollbar>
        </div>
    </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
export default {
    data() {
        return {
            
        }
    },
    components : {
        VuePerfectScrollbar
    }
}
</script>

<style lang="scss" scoped>
    .CookieSetting {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 36.90%;
        height: 80vh;
        background: rgba(#2C2C2C, 0.9);
        z-index: 99;
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.16);
        display: flex;
        padding: 30px 40px;
        .description {
            color: white;
            height: auto;
            width: 100%;
            .scroll-area {
                position: relative;
                margin: auto;
                width: 100%;
            }
            .topic {
                font-size: 18px;
                font-weight: bold;
            }
            .checkbox {
                font-weight: bold;
                
            }
            .textCheckbox {
                padding-left: 5%;
            }

            .container {
                display: block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 18px;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                }

                /* Hide the browser's default checkbox */
                .container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
                }

                /* Create a custom checkbox */
                .checkmark {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                }

                .checkmark-disabled {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: transparent;
                }

                /* On mouse-over, add a grey background color */
                .container:hover input ~ .checkmark {
                background-color: #ccc;
                }

                /* When the checkbox is checked, add a blue background */
                .container input:checked ~ .checkmark {
                background-color: white;
                }

                .container input:checked ~ .checkmark-disabled {
                background-color: rgba(white, 0.5);
                border-color: white;
                }

                /* Create the checkmark/indicator (hidden when not checked) */
                .checkmark:after {
                content: "";
                position: absolute;
                display: none;
                }

                .checkmark-disabled:after {
                content: "";
                position: absolute;
                display: none;
                }

                /* Show the checkmark when checked */
                .container input:checked ~ .checkmark:after {
                display: block;
                }

                .container input:checked ~ .checkmark-disabled:after {
                display: block;
                }

                /* Style the checkmark/indicator */
                .container .checkmark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid #228B5B;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                }

                .container .checkmark-disabled:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid #228B5B;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                }

            button {
                background-color: #E13036;
                border-color: #E13036;
                border-style: solid;
                border-width: 1px;
                color : white;
                font-weight: bold;
                text-align: center;
            }
        }
        
    }

@media only screen and (min-width: 1280px) {
    .CookieSetting {   
        .description {
            .scroll-area {
                height: 450px;
            }
            .topic {
            }
            .checkbox {
            }
            .textCheckbox {
            }
            button {
            }
        }
    }
}
@media only screen and (min-width: 1920px) {
    .CookieSetting {   
        .description {
            .scroll-area {
                height: 100vh;
            }
            .topic {
            }
            .checkbox {
            }
            .textCheckbox {
            }
            button {
            }
        }
        
    }
}
</style>
