<template>
    <div id="homeMenu" class="homeMenu" data-aos="zoom-in" data-aos-easing="linear" data-aos-duration="300" data-aos-delay="1000">
        <div class="slides-container">
            <div class="slide" v-for="(item, index) in titleMenu" :key="index">
                <router-link :to="{ path: item.link }" :class="{'isText': index == indexSlide}">{{ item.title }}</router-link>
                <div class="explore-mongolia ml-3" :class="{'isShow':item.title == 'First Time Traveler' && indexSlide == 1}">
                    <span>CLICK TO EXPLORE MONGOLIA</span>
                </div>
            </div>
        </div>
        <svg id="scroll-down" xmlns="http://www.w3.org/2000/svg" width="84" height="84" viewBox="0 0 84 84">
			<g id="Group_753" data-name="Group 753" transform="translate(-860 -897)">
				<g id="Group_52" data-name="Group 52" transform="translate(-83.18 -49)">
				<circle id="Ellipse_1" data-name="Ellipse 1" cx="42" cy="42" r="42" transform="translate(943.18 946)" opacity="0.294"/>
				<g id="Group_8" data-name="Group 8" transform="translate(966.46 959.859)">
					<path id="Path_32" data-name="Path 32" d="M287.576-3.611h-10.7A13.5,13.5,0,0,0,263.467,9.936V39.124A13.5,13.5,0,0,0,276.88,52.67h10.7a13.494,13.494,0,0,0,13.412-13.546V9.936A13.5,13.5,0,0,0,287.576-3.611Zm10.892,42.735a10.959,10.959,0,0,1-10.892,11h-10.7a10.959,10.959,0,0,1-10.893-11V9.936a10.961,10.961,0,0,1,10.893-11h10.7a10.96,10.96,0,0,1,10.892,11Z" transform="translate(-263.467 3.611)" fill="#fff"/>
					<path id="Path_33" data-name="Path 33" d="M291.766,36.613l-6.791,6.78V29.341a1.246,1.246,0,1,0-2.491,0V43.393l-6.792-6.78a1.225,1.225,0,0,0-1.761.026,1.3,1.3,0,0,0,.024,1.806l8.906,8.89.012.009a1.241,1.241,0,0,0,.388.258c.009,0,.019.005.028.009a1.161,1.161,0,0,0,.881,0c.009,0,.019,0,.028-.009a1.242,1.242,0,0,0,.388-.258s.008-.005.012-.009l8.905-8.89a1.3,1.3,0,0,0,.024-1.806A1.225,1.225,0,0,0,291.766,36.613Z" transform="translate(-264.969 -1.25)" fill="#fff"/>
					<path id="Path_34" data-name="Path 34" d="M285.221,15.225a3.335,3.335,0,1,0-3.334-3.335A3.335,3.335,0,0,0,285.221,15.225Z" transform="translate(-266.461 1.76)" fill="#fff"/>
				</g>
				</g>
			</g>
		</svg>
    </div>
</template>

<script>
import { TweenLite } from "gsap/all";
import { setTimeout } from 'timers';

export default {
    name : 'HomeMenu',
    data() {
        return {
            titleMenu : [
                {
                    title : 'Traveler Snapshots',
                    link : 'traveler-snapshots'
                },
                // {
                //     title : '#FeelMongolia',
                //     link : 'feelmongolia'
                // },
                {
                    title : 'First Time Traveler',
                    link : 'first-time-traveler'
                },
                {
                    title : 'Community Based Tourism',
                    link : 'themes/community-based'
                }
            ],
            slidesContainer : null,
            slides : null,
            currentSlide : null,
            isAnimating : false,
            indexSlide : 0
        } 
    },
    methods : {
        onKeyDown(event) {
            var PRESSED_KEY = event.keyCode;
            if (PRESSED_KEY == 40) {
                this.goToNextSlide(this.slides);
            } else if(PRESSED_KEY == 38) {
                this.goToPrevSlide(this.slides);
            }
            
        },

        goToPrevSlide(){
            if(this.currentSlide.prev().length)
            {
                this.goToSlide(this.currentSlide.prev());
            }
        },

        goToNextSlide(){
            if(this.currentSlide.next().length)
            {
                this.goToSlide(this.currentSlide.next());
            }
        },

        goToSlide($slide){
            //If the slides are not changing and there's such a slide
            if(!this.isAnimating && $slide.length)
            {
                //setting animating flag to true
                this.isAnimating = true;
                this.currentSlide = $slide;
                this.indexSlide = this.currentSlide.index();
                //Sliding to current slide
                TweenLite.to(this.slidesContainer, 0.2, {scrollTo: {y: 100 * this.currentSlide.index() }, onComplete: this.onSlideChangeEnd, onCompleteScope: this});
            }
        },

        onSlideChangeEnd() {
            this.isAnimating = false;
        },

        onMouseWheel(event){
            var e = window.event || event; // old IE support
            var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));

            if(delta < 0)
            {
                this.goToNextSlide(this.slides);
            }
            else if(delta > 0)
            {
                this.goToPrevSlide(this.slides);
            }

            event.preventDefault();
        }
    },
    mounted() {
        this.slidesContainer = $(".slides-container");
        this.slides = $(".slide");
        this.currentSlide = this.slides.first();
        let slideshow = document.getElementById("homeMenu");
        let mousewheelevt=(/Firefox/i.test(navigator.userAgent))? "DOMMouseScroll" : "mousewheel"
        document.addEventListener("keydown", this.onKeyDown);

        if (slideshow.attachEvent) //if IE (and Opera depending on user setting)
            slideshow.attachEvent("on"+mousewheelevt, this.onMouseWheel)
        else if (slideshow.addEventListener) //WC3 browsers
            slideshow.addEventListener(mousewheelevt, this.onMouseWheel, false)

        this.goToSlide(this.currentSlide);
        
        import('aos').then(AOS => AOS.init());
    }
    
}
</script>


<style lang="scss" scoped>
.homeMenu {
    height: 100vh;
    position: relative;
    #scroll-down {
        position: absolute;
        left: 46%;
        bottom: 70px;
        transform: translateX(-50%);
    }
    .slides-container {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0,-50%);
        width: 100%;
        height: 300px;
        overflow: hidden;
        z-index: 10;
        .slide {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 100px;
            overflow: hidden;
            font-size: 40px;
            transition: ease .2s;
            padding-top: 10px;

            &:first-child{
                margin-top: 100px;
            }
            &:last-child{
                margin-bottom: 100px;
            }

            a {
                color: #fff;
                opacity: .7;
                text-decoration: none;
            }

            a {
                transition: all .3s ease;
                &.isText {
                    font-size: 50px;
                    opacity: 1;
                }
            }

            .explore-mongolia {
                opacity: 0;
                color: #FFFFFF;
                font-size: 12px;
                font-weight: bold;
                text-align: left;
                border-radius: 50%;
                padding: 18px 15px 15px 12px;
                width: 90px;
                height: 90px;
                background-color: #E13036;
                transition: .3s ease-in-out;

                &.isShow {
                    opacity: 1;
                }
            }

            &:hover {
                cursor: pointer;

                a {
                    opacity: 1;
                    color:#E13036;
                }

                .explore-mongolia {
                    &:hover {
                        -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                        -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.75);
                        transition: .2s ease;
                    }
                }
            }
        }
    }
}
</style>

